

const Rodo = () => {
  return (
    <div className="rodo box-shadow1">
      <h6>Informacja o przetwarzaniu danych osobowych</h6>

      <p>
        Zgodnie z art. 13, 14, 15 i&nbsp;21 rozporządzenia Parlamentu Europejskiego i&nbsp;Rady (EU) 2016/679 z&nbsp;dnia 27 kwietnia 2016&nbsp;r. w&nbsp;sprawie ochrony osób
        fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w&nbsp;sprawie
        swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
        (tzw. RODO) informujemy, że:
      </p>
      <ol class="rodo_list">
        <li>
          Administratorem, czyli podmiotem decydującym o&nbsp;tym, jak będą
          wykorzystywane Pani/Pana dane osobowe, jest Bezpieczna Woda Artur
          Konopka z&nbsp;siedzibą ul. Zdrowa 13/5, 31-216 Kraków.
        </li>
        <li>Pani/Pana dane osobowe przetwarzane będą do celów związanych z:</li>
        <ul>
          <li>
            {" "}
            Realizacją przez Bezpieczna Woda Artur Konopka umów i&nbsp;zamówień.
          </li>
          <li>
            Przygotowywaniem ofert handlowych, dokumentacji technicznych i&nbsp;dokumentów wymaganych z&nbsp;realizowanych umów/zamówień.
          </li>
          <li>
            Realizacją sprzedaży i&nbsp;wykonywania usług świadczonych przez
            Bezpieczna Woda Artur Konopka
          </li>
          <li>
            Sporządzaniem dokumentacji księgowej tj. fakturowaniem i&nbsp;dokumentowaniem sprzedaży towarów i&nbsp;usług oferowanych przez
            Bezpieczna Woda Artur Konopka.
          </li>
          <li>
            Prowadzenia procesów reklamacyjnych, gwarancyjnych, windykacyjnych,
            weryfikacji wiarygodności płatniczej oraz działań marketingowych.
          </li>
        </ul>
        <li>
          {" "}
          Podstawą do przetwarzania tych danych jest art. 6 ust. 1&nbsp;Ogólnego
          rozporządzenia o&nbsp;ochronie danych osobowych (RODO).
        </li>
        <li>
          Odbiorcami Pani/Pana danych osobowych będą wyłącznie podmioty
          uprawnione do uzyskania danych osobowych na podstawie przepisów prawa.{" "}
        </li>
        <li>
          Pani/Pana dane osobowe przechowywane będą przez okres wymagany prawem
          zgodnie z celem dla, którego dane te zostały pozyskane i&nbsp;użyte.{" "}
        </li>
        <li>
          Posiada Pani/Pan prawo do żądania od administratora dostępu do danych
          osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania,
          wniesienia sprzeciwu wobec przetwarzania danych, przenoszenia danych
          oraz prawo do cofnięcia zgody. Cofnięcie zgody nie wpływa na zgodność
          z&nbsp;prawem przetwarzania, którego dokonano na podstawie zgody uzyskanej
          przed jej wycofaniem.
        </li>
        <li>Ma Pani/Pan prawo wniesienia skargi do organu nadzorczego.</li>
        <li>
          Podanie danych osobowych jest dobrowolne, jednak ich niepodanie może
          uniemożliwić nawiązanie współpracy ze Bezpieczna Woda Artur Konopka.
        </li>
      </ol>
    </div>
  );
};

export default Rodo;
