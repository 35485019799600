import React from "react";


function History(){

    return (
      <div className="page-container" >
      {/* <div className="page-container" style={{height:'60vh'}}> */}

        todo
      </div>
    );
}
export default History;